@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Almarai:wght@300&family=Archivo+Black&family=Candal&family=Crimson+Text&family=Dancing+Script:wght@500&family=Instrument+Serif&family=Italianno&family=Manrope&family=Montserrat:wght@200;300&family=Paytone+One&family=Philosopher&family=Roboto+Mono:wght@500&family=Varela&family=Young+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Almarai:wght@300&family=Archivo+Black&family=Candal&family=Crimson+Text&family=Dancing+Script:wght@500&family=Instrument+Serif&family=Italianno&family=Montserrat:wght@200;300&family=Paytone+One&family=Philosopher&family=Roboto+Mono:wght@500&family=Varela&family=Young+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Almarai:wght@300&family=Archivo+Black&family=Candal&family=Crimson+Text&family=Dancing+Script:wght@500&family=Instrument+Serif&family=Italianno&family=Montserrat:wght@200;300&family=Paytone+One&family=Philosopher&family=Roboto+Mono:wght@500&family=Rubik:wght@300&family=Varela&family=Young+Serif&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.navbar a {
  text-decoration: none;
}
.links a {
  background: transparent;
}
.images2 {
  height: 455px;
  width: 414px;
  margin-left: 57px;
}
@media (max-width: 767px) {
  body,
  html {
    overflow-x: hidden;
  }
  .images2 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .images2 {
    margin-left: 146px;
  }
}
